import React from 'react';
import styled from 'styled-components';

import { ArticleWithCreators } from 'apiFolder/directus-main';

import TabArticleWithCreators from 'components/article/TabArticleWithCreators';

interface IArticlesGrid {
	articles: ArticleWithCreators[];
}

const ArticlesGrid: React.FC<IArticlesGrid> = ({ articles }) => {
	return (
		<TextBlock>
			<Cards>
				<TextPar>
					{articles?.map((article) => (
						<TabArticleWithCreators
							key={article.articleId}
							img={article.image}
							title={article.heading}
							writtenBy={article.authors}
							lastReviewed={article.lastReviewed}
							link={article.slug}
						/>
					))}
				</TextPar>
			</Cards>
		</TextBlock>
	);
};

const TextBlock = styled.div`
	line-height: 23px;
`;

const TextPar = styled.div`
	transition: all 0.3s ease;

	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 60px 0 50px;

	@media (max-width: 1023px) {
		padding: 40px 0 30px;
	}
	@media (max-width: 767px) {
		padding: 30px 0 20px;
	}
`;

const Cards = styled.div`
	margin: 0 -15px;
`;

export default ArticlesGrid;
